import Api from "@/service/api";
import {downloadUtils} from "@utils/downloadUtils";

export default class SpectacleReservationService {
    spectacleReservations(filter) {
        return Api().get("/spectacleReservations",{params: filter}).then(res => {
                return res.data;
            }
        );
    }
    create(spectacleReservation) {
        return Api().post("/spectacleReservation", spectacleReservation).then(res => res.data);
    }
    update(spectacleReservation) {
        return Api().put("/spectacleReservation/" + spectacleReservation.spectacleReservationId, spectacleReservation).then(res => res.data);
    }
    delete(spectacleReservation) {
        return Api().delete("/spectacleReservation/" + spectacleReservation.spectacleReservationId).then(() => spectacleReservation);
    }
    getBill(spectacleReservationId) {
        return downloadUtils("/spectacleReservations/" + spectacleReservationId + "/bill");
    }

}
