import {createStore} from 'vuex'
import TokenService from "../service/tokenService";
import {createRouter as route} from "vue-router";

//import route from '@routes';

export default createStore({
    state: {
        currentUser: getSavedState('auth.currentUser'),
        userInformation: getSavedState('userInformation'),
    },


    mutations: {
        SET_CURRENT_USER(state, newValue) {
            state.currentUser = newValue
            saveState('auth.currentUser', newValue)
        },
    },
    getters: {
        // Whether the user is currently logged in.
        loggedIn(state) {
            return !!state.currentUser
        },
        currentUserAuthorities(state){
            return state?.userInformation?.authorities;
        },
        refreshPromise(state){
            return state.refreshPromise;
        }
    },

    actions: {
        // This is automatically run in `src/state/store.js` when the app
        // starts, along with any other actions named `init` in other modules.
        init({dispatch}) {
            dispatch('validate')
        },

        // Logs in the current user.
        logIn({commit, dispatch, getters}, {username, password} = {}) {
            commit('SET_CURRENT_USER', null)
            console.log("log in" + getters.loggedIn)
            if (getters.loggedIn) return dispatch('validate');
            var user = {login: username, password: password};
            console.log("user")
            console.log(user)
            return TokenService.login(user).then(data => {
                const user = data
                console.log(user)
                commit('SET_CURRENT_USER', user)
                //commit('SET_USER_INFO', user)
                return user
            });
        },

        // Logs out the current user.
        logOut({commit}) {
            commit('SET_CURRENT_USER', null);
            route.push({name: "login"});
        },

        logOutExpired({commit}) {
            commit('SET_CURRENT_USER', null);
            route.push({name: "login", query: {redirectFrom: route.currentRoute.value.fullPath}});
        },

        // Validates the current user's token and refreshes it
        // with new data from the API.
        validate({state}) {
            if (!state.currentUser) return Promise.resolve(null)
            return state.currentUser;
        },
    },
    modules: {}
})

// ===
// Private helpers
// ===

function getSavedState(key) {
    return JSON.parse(window.localStorage.getItem(key))
}

function saveState(key, state) {
    window.localStorage.setItem(key, JSON.stringify(state))
}
