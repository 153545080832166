import Dashboard from '../components/Dashboard.vue';
export default [
    {
        path: '/public/login',
        name: 'login',
        component: () => import('@pages/Login.vue'),
        meta: {
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in

                    // Continue to the login page
                    next()

            },
        },
    },
    {
        path: '/dashboard',
        name: 'dashboard',
        component: Dashboard,
        meta: {
            authRequired: true,
        },
    },
    {
        path: '/public/spectacle/:spectacleId/register/:dateSpectacleId',
        name: 'spectacleRegister',
        component: () => import('../pages/Spectacle/SpectacleRegister.vue'),
        meta: {
            //authRequired: false,
        },
    },

    {
        path: '/public/spectacles',
        name: 'spectacleList',
        component: () => import('../pages/Spectacle/SpectacleList.vue'),
        meta: {
            //authRequired: false,
        },
    },

    {
        path: '/formlayout',
        name: 'formlayout',
        component: () => import('../components/FormLayoutDemo.vue'),
        meta: {
            //authRequired: true,
        },
    },
    {
        path: '/input',
        name: 'input',
        component: () => import('../components/InputDemo.vue'),
        meta: {
            //authRequired: true,
        },
    },
    {
        path: '/floatlabel',
        name: 'floatlabel',
        component: () => import('../components/FloatLabelDemo.vue'),
        meta: {
            //authRequired: true,
        },
    },
    {
        path: '/invalidstate',
        name: 'invalidstate',
        component: () => import('../components/InvalidStateDemo.vue')
    },
    {
        path: '/button',
        name: 'button',
        component: () => import('../components/ButtonDemo.vue')
    },
    {
        path: '/table',
        name: 'table',
        component: () => import('../components/TableDemo.vue')
    },
    {
        path: '/list',
        name: 'list',
        component: () => import('../components/ListDemo.vue')
    },
    {
        path: '/tree',
        name: 'tree',
        component: () => import('../components/TreeDemo.vue')
    },
    {
        path: '/panel',
        name: 'panel',
        component: () => import('../components/PanelsDemo.vue')
    },
    {
        path: '/overlay',
        name: 'overlay',
        component: () => import('../components/OverlayDemo.vue')
    },
    {
        path: '/media',
        name: 'media',
        component: () => import('../components/MediaDemo.vue')
    },
    {
        path: '/menu',
        component: () => import('../components/MenuDemo.vue'),
        children: [
            {
                path: '',
                component: () => import('../components/menu/PersonalDemo.vue')
            },
            {
                path: '/menu/seat',
                component: () => import('../components/menu/SeatDemo.vue')
            },
            {
                path: '/menu/payment',
                component: () => import('../components/menu/PaymentDemo.vue')
            },
            {
                path: '/menu/confirmation',
                component: () => import('../components/menu/ConfirmationDemo.vue')
            },
        ],
    },
    {
        path: '/messages',
        name: 'messages',
        component: () => import('../components/MessagesDemo.vue')
    },
    {
        path: '/file',
        name: 'file',
        component: () => import('../components/FileDemo.vue')
    },
    {
        path: '/chart',
        name: 'chart',
        component: () => import('../components/ChartDemo.vue')
    },
    {
        path: '/misc',
        name: 'misc',
        component: () => import('../components/MiscDemo.vue')
    },
    {
            path: '/crud',
            name: 'demoCrud',
            component: () => import('../pages/CrudDemo.vue')
        },
    {
        path: '/crud/user',
        name: 'userCrud',
        component: () => import('../pages/User/UserCRUD.vue'),
        meta: {
            authRequired: true,
        },
    },
    {
        path: '/crud/spectacle',
        name: 'spectacleCrud',
        component: () => import('../pages/Spectacle/SpectacleCRUD.vue'),
        meta: {
            authRequired: true,
        },
    },
    {
        path: '/crud/salle',
        name: 'salleCrud',
        component: () => import('../pages/Salle/SalleCRUD.vue'),
        meta: {
            authRequired: true,
        },
    },


    {
        path: '/spectacle/:spectacleId',
        name: 'spectacle',
        component: () => import('../pages/Spectacle/Spectacle.vue'),
        meta: {
            authRequired: true,
        },
    },
    {
        path: '/timeline',
        name: 'timeline',
        component: () => import('../pages/TimelineDemo.vue')
    },
    {
        path: '/empty',
        name: 'empty',
        component: () => import('../components/EmptyPage.vue')
    },
    {
        path: '/documentation',
        name: 'documentation',
        component: () => import('../components/Documentation.vue')
    },
    {
        path: '/blocks',
        name: 'blocks',
        component: () => import('../components/BlocksDemo.vue')
    },
    {
        path: '/icons',
        name: 'icons',
        component: () => import('../components/IconsDemo.vue')
    }
];


