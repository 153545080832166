<template>
  <div class="grid">
    <div class="col-12 lg:col-6 xl:col-3">
      <router-link :to="{name: 'spectacleList'}" target="_blank">
        <div class="card mb-0">
          <div class="flex justify-content-between mb-3">
            <div>
              <span class="block text-500 font-medium mb-3">Liste des spectacles</span>
            </div>
            <div class="flex align-items-center justify-content-center bg-blue-100 border-round"
                 style="width:2.5rem;height:2.5rem">
              <i class="pi pi-ticket text-blue-500 text-xl"></i>
            </div>
          </div>
        </div>
      </router-link>
    </div>
  </div>
  <DataTable class="mt-5" :value="this.reservations" :paginator="true" :rows="10" :loading="this.loadingReservations"
             paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
             :rowsPerPageOptions="[5,10,25]"
             currentPageReportTemplate="{first} à {last} des {totalRecords} spectacles" responsiveLayout="scroll">
    <template #header>
      <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
        <h5 class="m-0">Réservations en attente</h5>
      </div>
    </template>
    <Column field="name" header="Spectacle" :sortable="true" headerStyle="width:20%; min-width:10rem;">
      <template #body="slotProps">
        <span class="p-column-title"></span>
        {{ slotProps.data.spectacleName }}
      </template>
    </Column>
    <Column field="name" header="École" :sortable="true" headerStyle="width:20%; min-width:10rem;">
      <template #body="slotProps">
        <span class="p-column-title"></span>
        {{ slotProps.data.name }}
      </template>
    </Column>

    <Column field="companions" header="Nombre d'accompagnateurs" :sortable="true"
            headerStyle="width:22%; min-width:10rem;">
      <template #body="slotProps">
        <span class="p-column-title"></span>
        {{ slotProps.data.companions }}
      </template>
    </Column>

    <Column field="nbStudents" header="Nombre d'élèves" :sortable="true" headerStyle="width:15%; min-width:10rem;">
      <template #body="slotProps">
        <span class="p-column-title"></span>
        {{ slotProps.data.nbStudents }}
      </template>
    </Column>

    <Column field="accepted" header="Validation" :sortable="true" style="text-align: left">
      <template #body="slotProps">
        <template  v-if="slotProps.data.accepted === 'waiting' || slotProps.data.accepted === 'pending'">
          <Button icon="pi pi-check" class="button-custom button-valid state-badge status-valid mr-2" @click="openReservationConfirm(slotProps.data, 'valid')" />
          <Button icon ="pi pi-envelope"  class="button-custom button-info state-badge  mr-2" @click="openSendMail(slotProps.data)" />
          <Button icon="pi pi-trash" class="button-custom button-reject state-badge status-rejected" @click="openReservationConfirm(slotProps.data, 'reject')" />
        </template>
        <span v-if="slotProps.data.accepted == 'valid'" class="state-badge status-valid">Validé <i class="pi pi-check ml-2" style="color: green"/></span>
        <span v-if="slotProps.data.accepted == 'reject'" class="state-badge status-rejected">Refusé <i class="pi pi-trash ml-2" style="color:indianred"/></span>

      </template>
    </Column>



    <Column field="spectacleReservationId" header="Actions" style="text-align: left">
      <template #body="slotProps">
        <Button icon="pi pi-save" class="button-custom button-valid"
                @click="downloadBill(slotProps.data.spectacleReservationId)"/>
      </template>
    </Column>

  </DataTable>

  <Dialog v-model:visible="confirmValidation" :style="{width: '450px'}" header="Confirmer" :modal="true">
    <div class="flex align-items-center justify-content-center">
      <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem"/>
      <span v-if="reservation.spectacleName">Voulez-vous vraiment {{ tempReservationStateTrad }} la reservation de l'école <b>{{
          reservation.name
        }}</b> ?</span>
    </div>



    <div class="col-12">
      <label class="mb-1">Commentaire (optionnel) :</label>
      <Textarea v-model="this.reservation.comment" :autoResize="true" class="col-12 mt-1"></Textarea>
    </div>

    <div class="col-12" v-if="tempReservationStateTrad === 'valider'">
      <ak-input-number v-model="this.reservation.freeGuide" label="Nombre d'accompagnateurs gratuits :"/>
      <Slider v-model="this.reservation.freeGuide" :min="2" max="15" class="col-12 mt-1"/>
    </div>
    <div class="p-dialog-footer">
      <Button label="Non" icon="pi pi-times" class="p-button-text" @click="closeReservationConfirm"/>
      <Button label="Oui" icon="pi pi-check" class="p-button-text" @click="updateReservation"/>
    </div>
  </Dialog>
  <Dialog v-model:visible="sendMailPopup" :style="{width: '450px'}" header="Prendre contact ... " :modal="true">

    <div class="col-12">
      <label class="mb-1">Message à envoyer :</label>
      <Textarea v-model="this.reservation.comment" :autoResize="true" class="col-12 mt-1"></Textarea>
    </div>

    <div class="p-dialog-footer">
      <Button label="Envoyer" icon="pi pi-check" class="p-button-text" @click="updateReservation" />
    </div>
  </Dialog>

</template>

<script>
import ProductService from '../service/ProductService';
import SpectacleReservationService from "@services/SpectacleReservationService";
import AkInputNumber from "@/components/input/AkInputNumber";

export default {
  components: {AkInputNumber},
  data() {

    return {
      products: null,
      lineData: {
        labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
        datasets: [
          {
            label: 'Revenue',
            data: [65, 59, 80, 81, 56, 55, 40],
            fill: false,
            backgroundColor: '#2f4860',
            borderColor: '#2f4860',
            tension: 0.4
          },
          {
            label: 'Sales',
            data: [28, 48, 40, 19, 86, 27, 90],
            fill: false,
            backgroundColor: '#00bb7e',
            borderColor: '#00bb7e',
            tension: 0.4
          }
        ]
      },
      items: [
        {label: 'Add New', icon: 'pi pi-fw pi-plus'},
        {label: 'Remove', icon: 'pi pi-fw pi-minus'}
      ],
      reservations: [],
      spectacleReservationService: null,
      loadingReservations: true,
      confirmValidation: false,
      reservation: {},
      tempReservationState: null,
      sendMailPopup : false,
    }
  },
  productService: null,

  computed: {
    tempReservationStateTrad() {
      if (this.tempReservationState == 'valid') {
        return 'valider';
      } else return 'refuser';
    }
  },

  created() {
    this.productService = new ProductService();
    this.spectacleReservationService = new SpectacleReservationService();
  },
  mounted() {
    this.productService.getProductsSmall().then(data => this.products = data);
    let filters = {
      accepted: 'waiting'
    }
    this.spectacleReservationService.spectacleReservations(filters).then(data => {
      this.reservations = data;
      this.loadingReservations = false;
    })
  },
  methods: {
    formatCurrency(value) {
      return value.toLocaleString('en-US', {style: 'currency', currency: 'USD'});
    },
    downloadBill(spectacleReservationId) {
      this.spectacleReservationService.getBill(spectacleReservationId);
    },
    openReservationConfirm(reservation, state) {
      this.reservation = reservation;
      this.tempReservationState = state;
      this.confirmValidation = true;
    },

    openSendMail(reservation){
      this.reservation = reservation;
      this.sendMailPopup = true;
      this.tempReservationState = "waiting";
    },

    closeReservationConfirm() {
      this.reservation = undefined;
      this.tempReservationState = undefined;
      this.confirmValidation = false;
    },
    updateReservation(){
      this.reservation.accepted = this.tempReservationState;
      this.spectacleReservationService.update(this.reservation).then(
          this.$toast.add({severity:'success', summary: 'Envoyé', detail: "L'email a bien été envoyé", life: 3000})).catch(() => {
        this.$toast.add({severity:'error', summary: 'Erreur', detail: "Une erreur est survenue", life: 3000});
      });
      this.confirmValidation = false;
      this.sendMailPopup = false;

    },
  }
}
</script>