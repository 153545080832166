


<template>
  <template v-if="loading">
    <ProgressSpinner />
  </template>
  <template v-else>
    <template v-if="isPublic">
      <metainfo>
        <template v-slot:title="{ content }">
          {{ content ? this.$i18n.t(content) + ` | ` + this.$i18n.t('app_name') : this.$i18n.t('app_name') }}
        </template>
      </metainfo>
      <RouterView :key="$route.fullPath"/>
    </template>
    <template v-if="!isPublic">
      <AppLayout/>
    </template>
  </template>
</template>

<script>
import AppLayout from './AppLayout.vue';

export default {

  mounted() {
  },

  data() {
    return {
      loading: true,
      isPublic: true
    }

  },

  watch: {
    // à chaque fois que la question change, cette fonction s'exécutera
    $route: function () {
      this.isPublic = this.isPublic = this.$route.fullPath.includes("public");
      console.log(this.$route.fullPath)
      if(this.$route.fullPath == '/'){
        this.$router.push({name: 'dashboard'})
      }
      if(this.$route.fullPath !== '/'){
        this.loading = false;
      }
    },
  },
    methods: {},

    components: {
      'AppLayout': AppLayout,
    },

}
</script>

<style lang="scss">
@import './App.scss';
</style>
